export class Data {
  nodes = {};
  scale = 1;

  /**
   * set nodes and righs from user
   * @param {*} config
   */
  setNodes(config) {
    //console.log('🚀 ~ setUserInfo ~ config:', config);
    const nodes = {};
    config.objects.nodes &&
      config.objects.nodes.forEach((el) => {
        el.racks.forEach((el1) => {
          el1.node = el.id;
          nodes[el1.id] = el1;
        });
      });
    config.objects.payments &&
      config.objects.payments.forEach((el) => {
        if (nodes[el.id]) {
          console.log('error', el);
        } else {
          nodes[el.id] = el;
        }
      });
    config.objects.extensions &&
      config.objects.extensions.forEach((el) => {
        if (nodes[el.id]) {
          console.log('error', el);
        } else {
          nodes[el.id] = el;
        }
      });

    config.objects.gooses &&
      config.objects.gooses.forEach((el) => {
        if (nodes[el.id]) {
          console.log('error', el);
        } else {
          nodes[el.id] = el;
        }
      });

    this.nodes = nodes;
    return nodes;
  }

  /**
   * get nodes
   * @returns
   */
  getNodes() {
    return this.nodes;
  }

  setScale(scale) {
    this.scale = scale;
  }
  
  getScale() {
    return this.scale;
  }
}
