import { useState, useEffect } from 'react';
import ReactLassoSelect from 'react-lasso-select';
import PropTypes from 'prop-types';
import moment from 'moment';

// import { data } from 'services/data';
// import { api } from 'api';
import { Content, Place, RectorPlace, Buttons, Info } from './options.cam-poly.styled';
import services from '../../../../services';
import { VideoBlockCap } from '../racksControl/styledComponents';
// import VideoBlockUrls from '../racksControl/VideoBlockUrls';
import { GrayButton } from '../../../shared/buttons';

const test = false;
const maxW = 500;
const maxH = 310;
const kw = 430 / maxW;
const kh = 280 / maxH;
let createTime;
const toPercentX = (val, tofixed = false) => {
  let v = (100 * val) / maxW / kw;
  if (tofixed) {
    v = v.toFixed(2);
  }
  return v;
};
const toPercentY = (val, tofixed = false) => {
  let v = (100 * val) / maxH / kh;
  if (tofixed) {
    v = v.toFixed(2);
  }
  return v;
};

export const OptionsCamPoly = (props) => {
  const { sendControlMsg, id, status, add } = props;
  // console.log('🚀 ~ OptionsCamPoly ~ status:', status);
  const [selection, setSelection] = useState(false);
  const [cams, setCams] = useState(null);
  const { mode, recognition } = status;
  // console.log('render');

  // size
  // const size = useWindowSize();
  // const scale = k;//data.getScale();

  const getDefaultPoints = () => {
    const data =
      !recognition || !recognition.polygon
        ? [
            [0, 0],
            [100, 0],
            [100, 100],
            [0, 100],
          ]
        : recognition.polygon;
    return data.map((el) => ({ x: Math.round((maxW * el[0]) / 100) * kw, y: Math.round((maxH * el[1]) / 100) * kh }));
  };

  const [points, setPoints] = useState(getDefaultPoints);
  // console.log('🚀 ~ OptionsCamPoly ~ points:', points);

  const getVideoUrls = (msg) => {
    if (msg.target === id) {
      setCams(services.prepareVideoUrls(msg));
    }
  };

  // on mount
  useEffect(() => {
    createTime = moment().unix();
    sendControlMsg('get_video_urls');
    window.socket.on('get_video_urls', getVideoUrls);
    return () => {
      window.socket.off('get_video_urls', getVideoUrls);
    };
  }, []);

  /**
   * start selection
   */
  const startSelection = () => {
    setSelection(true);
  };

  /**
   * save selection
   */
  const saveSelection = () => {
    // console.log('save', points);
    if (points.length < 4) {
      return;
    }

    const coords = points.map((el) => [parseFloat(toPercentX(el.x, true)), parseFloat(toPercentX(el.y, true))]);

    // api.call('settings.set_recognition_polygon', { target: id, user_id: window.user.getId(), coords }).then(() => {
    //   setSelection(false);
    // });
    sendControlMsg(
      'settings.set_recognition_polygon',
      () => {
        setSelection(false);
      },
      { coords }
    );
  };

  /**
   * get selection string
   * @returns
   */
  const getSelectionStr = () => {
    return points.map(({ x, y }) => `[${toPercentX(x, true)}%, ${toPercentY(y, true)}%]`).join(', ');
  };

  const getImageSrc = () => {
    if (!cams || !cams.length) {
      return '';
    }
    const src = cams[0].url;
    return test ? '/images/test/' + id + '.jpg?=ct=' + createTime : src;
  };

  // render

  let h1 = maxH + 'px';
  const offline = mode === 'offline';
  let cl2 = offline ? 'offline1' : !mode ? 'loading1' : '';
  const src = getImageSrc();
  const valid = selection && points.length > 3;

  return (
    <Content>
      <Place>
        {cams === null ||
          (!cams.length && (
            <VideoBlockCap
              className={cl2}
              style={{
                height: h1,
                width: maxW + 'px',
              }}
            />
          ))}
        <RectorPlace>
          <ReactLassoSelect
            disabled={!selection}
            value={points}
            src={src}
            onChange={(value) => {
              setPoints(value);
            }}
            // style={{
            //   transformOrigin: '0 0',
            //   transform: `scale(${scale})`,
            // }}
            imageStyle={{ width: `${maxW}px`, height: `${maxH}px` }}
            // viewBox={{
            //   left: 0,
            //   top: 0,
            //   width: maxW,
            //   height: maxH,
            // }}
            // onComplete={(value) => {
            //   if (!value.length) return;
            //   getCanvas(src, value, (err, canvas) => {
            //     if (!err) {
            //       setClippedImg(canvas.toDataURL());
            //     }
            //   });
            // }}
          />
        </RectorPlace>
      </Place>
      <Info>{getSelectionStr()}</Info>
      <Buttons className={cl2}>
        <GrayButton className="btn" onClick={startSelection} disabled={selection}>
          Выделить область ГРЗ
        </GrayButton>
        <GrayButton className="btn" onClick={saveSelection} disabled={!valid}>
          Сохранить область ГРЗ
        </GrayButton>
        {add}
      </Buttons>
    </Content>
  );
};

OptionsCamPoly.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.object,
  sendControlMsg: PropTypes.func.isRequired,
  add: PropTypes.any,
};
