import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ResizableRect from 'react-resizable-rotatable-draggable';

import { Content, Place, RectorPlace, Buttons, Info } from './options.cam.styled';
import services from '../../../../services';
import { VideoBlockCap } from '../racksControl/styledComponents';
import VideoBlockUrls from '../racksControl/VideoBlockUrls';
import { GrayButton } from '../../../shared/buttons';

const maxW = 500;
const maxH = 310;
//const k = 0.9;

const toPercentX = (val, tofixed = false) => {
  let v = (100 * val) / maxW;
  if (tofixed) {
    v = v.toFixed(2);
  }
  return v;
};
const toPercentY = (val, tofixed = false) => {
  let v = (100 * val) / maxH;
  if (tofixed) {
    v = v.toFixed(2);
  }
  return v;
};

export class OptionsCam extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selection: false,
      activeCam: '1',
      cams: null,
      x: 0,
      y: 0,
      w: 100,
      h: 100,
      width: maxW,
      height: maxH,
      top: 0,
      left: 0,
      rotateAngle: 0,
    };
  }

  componentDidMount = () => {
    this._ismounted = true;
    this.createtime = moment().unix();

    this.props.sendControlMsg('get_video_urls');
    window.socket.on('get_video_urls', (msg) => {
      //console.log('🚀 ~ window.socket.on ~ msg', msg, this.props.id, this._ismounted);
      if (!this._ismounted) {
        return;
      }
      if (msg.target === this.props.id) {
        this.setState({ cams: services.prepareVideoUrls(msg) });
      }
    });
  };

  componentWillUnmount() {
    this._ismounted = false;
  }

  static getDerivedStateFromProps(props, state) {
    const { status } = props;
    if (!status) {
      return null;
    }
    const { recognition } = status;
    if (!recognition) {
      return null;
    }
    const { x, y, w, h } = recognition;

    if (x !== state.x || y !== state.y || w !== state.w || h !== state.h) {
      const left = Math.round((maxW * x) / 100);
      const top = Math.round((maxH * y) / 100);
      const width = Math.round((maxW * w) / 100);
      const height = Math.round((maxH * h) / 100);
      return {
        x: x,
        y: y,
        w: w,
        h: h,
        left: left,
        top: top,
        width: width,
        height: height,
      };
    }

    return null;
  }

  handleResize = (style) => {
    // type is a string and it shows which resize-handler you clicked
    // e.g. if you clicked top-right handler, then type is 'tr'
    let { top, left, width, height } = style;
    top = Math.round(top);
    left = Math.round(left);
    width = Math.round(width);
    height = Math.round(height);
    if (top < 0) {
      top = 0;
    }
    if (left < 0) {
      left = 0;
    }
    if (width + left > maxW) {
      width = maxW - left;
    }
    if (height + top > maxH) {
      height = maxH - top;
    }

    this.setState({
      top,
      left,
      width,
      height,
    });
  };

  handleDrag = (deltaX, deltaY) => {
    const { left, top, width, height, selection } = this.state;
    if (!selection) {
      return;
    }
    let left1 = left + deltaX;
    let top1 = top + deltaY;
    if (top1 < 0) {
      top1 = 0;
    }
    if (left1 < 0) {
      left1 = 0;
    }
    if (left1 + width > maxW) {
      left1 = maxW - width;
    }
    if (top1 + height > maxH) {
      top1 = maxH - height;
    }

    this.setState({
      left: left1,
      top: top1,
    });
  };

  getSelectionStr() {
    const { left, top, width, height } = this.state;
    //console.log('🚀 ~ getSelectionStr ~ left, top, width, height', left, top, width, height);

    return `x: ${toPercentX(left, true)}%, y: ${toPercentY(top, true)}%, w: ${toPercentX(width, true)}%, h: ${toPercentY(height, true)}%`;
  }

  startSelection = () => {
    this.setState({ selection: true });
  };

  saveSelection = () => {
    const { left, top, width, height } = this.state;
    const x = toPercentX(left);
    const y = toPercentY(top);
    const w = toPercentX(width);
    const h = toPercentY(height);
    this.props.sendControlMsg(
      'settings.set_recognition_area',
      () => {
        this.setState({ selection: false });
      },
      { x: x, y: y, w: w, h: h }
    );
  };

  render() {
    const { cams, selection, left, top, width, height } = this.state;
    //console.log('🚀 ~ render ~ cams', cams);
    const { status, add } = this.props;
    const { mode } = status;
    let h1 = maxH + 'px';
    const offline = mode === 'offline';
    let cl2 = offline ? 'offline1' : !mode ? 'loading1' : '';

    return (
      <Content>
        <Place>
          {cams === null || !cams.length ? (
            <VideoBlockCap
              className={cl2}
              style={{
                height: h1,
                width: maxW + 'px',
              }}
            />
          ) : (
            <VideoBlockUrls key="vblock1" createtime={this.createtime} rackId={this.props.id} camHeight={maxH + 'px'} cams={[cams[0]]} />
          )}
          <RectorPlace>
            <ResizableRect
              left={left}
              top={top}
              width={width}
              height={height}
              rotatable={false}
              //rotateAngle={rotateAngle}
              // aspectRatio={false}
              minWidth={100}
              minHeight={100}
              zoomable={selection ? 'n, w, s, e, nw, ne, se, sw' : ''}
              // onResizeStart={this.handleResizeStart}
              onResize={this.handleResize}
              // onResizeEnd={this.handleUp}
              // onDragStart={this.handleDragStart}
              onDrag={this.handleDrag}
              // onDragEnd={this.handleDragEnd}
            />
          </RectorPlace>
        </Place>
        <Info>{this.getSelectionStr()}</Info>
        <Buttons className={cl2}>
          <GrayButton className="btn" onClick={this.startSelection} disabled={selection}>
            Выделить область ГРЗ
          </GrayButton>
          <GrayButton className="btn" onClick={this.saveSelection} disabled={!selection}>
            Сохранить область ГРЗ
          </GrayButton>
          {add}
        </Buttons>
      </Content>
    );
  }
}

OptionsCam.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.object,
  sendControlMsg: PropTypes.func.isRequired,
  add: PropTypes.any,
};
